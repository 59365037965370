import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/SEO/SEOPage';
import Layout from '../components/shared/layout';
import { breakpoints, wrapper } from '../utils/style';
import styled from '@emotion/styled';
import Img from 'gatsby-image';
import ButtonLink from '../components/shared/ButtonLink';

const Wrapper = styled('div')`
  ${wrapper}
  margin: 30px auto;
  .img-wrap {
    max-width: 549px;
    margin: 0 auto;
  }
  .bottom-block {
    text-align: center;
    h1 {
      margin: 56px 0;
      font-size: 52px;
      font-weight: 800;
    }
    .bottom-button {
      font-size: 16px;
    }
  }
  
  @media (max-width: ${breakpoints.md}) {
    margin: 90px auto;
    .img-wrap {
      max-width: 288px;
    }
    .bottom-block {
      h1 {
        font-size: 18px;
        margin: 32px 0 24px;
      }
    }
  }
`;

const NotFoundPage = ({ data }) => (
  <Layout>
    <SEO title='404: Not found'/>
    <Wrapper>
      <div className="img-wrap">
        {data.missing.childImageSharp.fluid ?
          <Img alt="404" fluid={data.missing.childImageSharp.fluid} fadeIn={false} loading='eager' />
          : "404"
        }
      </div>
      <div className="bottom-block">
        <h1>เราไม่พบหน้าเว็บที่ค้นหา</h1>
        <ButtonLink to="/" iconName='' className="button transparent-button bottom-button" text="คลิกที่ลิงก์นี้เพื่อกลับไปสู่หน้าหลัก" ariaText="คลิกที่ลิงก์นี้เพื่อกลับไปสู่หน้าหลัก"/>
      </div>
    </Wrapper>
  </Layout>
);

export default NotFoundPage;

// Set here the ID of the home page.
export const pageQuery = graphql`
  query {
    missing: file(relativePath: { eq: "404.png" }) {
      childImageSharp {
          fluid(maxWidth: 549) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }   
  }
`;
